import H1 from "Components/H/H1";
import React from "react";

const Header = ({ title = "", parag = "" }) => {
  return (
    <div>
      <H1 className="text-center mt-[2vw]">{title}</H1>
      <p className="w-1/3 text-center mx-auto mb-[3vw] text-[1vw]">{parag}</p>
    </div>
  );
};
export default Header;
