import React from "react";

const Switcher = (props) => {
  const { showType, setShowType } = props;

  return (
    <div className="px-[0.5vw] py-[0.5vw] mb-[5vw] text-[1.1vw] gap-[0.5vw] rounded-[0.6vw] w-fit font-semibold bg-bgGrayDark mx-auto  flex items-center justify-center">
      <SwitcherButton
        active={showType === "monthly"}
        onClick={() => setShowType("monthly")}
      >
        Monthly
      </SwitcherButton>
      <SwitcherButton
        active={showType === "annually"}
        onClick={() => setShowType("annually")}
      >
        Annually{" "}
        <span className="text-mainColor rounded-[0.3vw]">save 1.6%</span>
      </SwitcherButton>
    </div>
  );
};

const SwitcherButton = ({ children, active = false, onClick }) => {
  let activeClass = active ? "text-white bg-black" : "text-black";

  return (
    <div
      onClick={onClick}
      className={`px-[2vw] py-[0.7vw] rounded-[0.3vw] cursor-pointer transition-all duration-200 ${activeClass}`}
    >
      {children}
    </div>
  );
};

export default Switcher;
