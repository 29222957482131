import React from "react";
import Header from "../Headers/Header";
import Container from "Components/Container/Container";
import { CompaireTable } from "./Fragments";

function Compare() {
  return (
    <Container className="mt-[4vw]">
      <Header
        title="Compare plans"
        parag="Personalized Fitness Support For You Support For You."
      />
      <CompaireTable />
    </Container>
  );
}

export default Compare;
