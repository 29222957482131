import H1 from "Components/H/H1";
import React from "react";
import InfinitySlider from "Components/InfinitySlider/InfinitySlider";

function Partners() {
  return (
    <div className="border-y-2 border-border py-[2vw]">
      <H1 className="text-center mb-[1vw]">Meet our Partners</H1>
      <InfinitySlider />
    </div>
  );
}

export default Partners;
