import { GapNavbarSpacer } from "Layouts/Navbar/Navbar";
import React, { useState } from "react";
import Switcher from "./Switcher/Switcher";
import Header from "./Headers/Header";
import PricingPlans from "./PricingPlans/PricingPlans";
import Partners from "./Partners/Partners";
import Compare from "./Compare/Compare";

function PricingPage() {
  const [showType, setShowType] = useState("monthly");

  return (
    <div className="pb-[5vw]">
      <GapNavbarSpacer />
      <Header
        title="Our pricing plans"
        parag="Personalized Fitness Support For You Support For You."
      />
      <Switcher showType={showType} setShowType={setShowType} />
      <PricingPlans showType={showType} />
      <Partners />
      <Compare />
    </div>
  );
}

export default PricingPage;
