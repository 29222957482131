import React from "react";
import { datas, items } from "./data";

export const HeaderCell = ({
  className = "",
  data = datas.free,
  type = "free",
}) => {
  const { title, icon, description, price } = data;

  const themes = {
    free: {
      button:
        "bg-white text-black hover:bg-black hover:text-white border-2 border-border",
    },
    pro: {
      button: "bg-black text-white hover:bg-mainColor hover:text-white",
    },
    premium: {
      button: "bg-[#DBAB1C] text-white",
    },
  };

  return (
    <div className={`w-1/4 py-[2.5vw] ${className}`}>
      <HeaderText title={title} icon={icon} />
      <p className={`text-center text-[1.4vw] mx-auto font-medium`}>{price}</p>
      <p className={`text-center text-[1vw] mx-auto`}>{description}</p>
      <ButtonStarted ct={themes[type]} />
    </div>
  );
};

export const HeaderText = ({
  title = "",
  icon = "/svgs/icons/bolt.png",
  ct = {},
}) => {
  return (
    <div className="w-full">
      <div className="w-full flex items-center justify-center">
        <img
          src={icon}
          className="w-[1.6vw] h-[1.6vw] mr-[0.3vw] mb-[0.2vw]"
          alt={title}
        />
        <h1 className={`text-center text-[1.7vw] font-bold ${ct?.title}`}>
          {title}
        </h1>
      </div>
    </div>
  );
};

export const ButtonStarted = ({ ct = {} }) => {
  return (
    <div className="w-full mt-[1vw]">
      <button
        className={`text-[1vw] font-medium w-[75%] py-[0.8vw] rounded-full mx-auto block transition-all duration-200 hover:scale-105 ${ct?.button}`}
      >
        Get Started
      </button>
    </div>
  );
};

export const HeaderRow = () => {
  return (
    <div className="w-full flex items-stretch justify-start">
      <div className="w-1/4" />
      <HeaderCell className="" data={datas.free} type="free" />
      <HeaderCell
        className="border-x-[3px] border-borderGray"
        data={datas.pro}
        type="pro"
      />
      <HeaderCell className="" data={datas.premium} type="premium" />
    </div>
  );
};

//  ROWS ##############################

export const CompaireTable = () => {
  return (
    <div className="w-full border-[3px] border-borderGray rounded-[2vw] relative">
      <HeaderRow />
      {items.map((item, key) => {
        return <RowItem key={key} item={item} />;
      })}
      <BorderBlack />
    </div>
  );
};

const BorderBlack = () => {
  return (
    <div className="w-1/4 h-full top-0 right-0 outline outline-black rounded-l-none rounded-[2vw] absolute" />
  );
};

const RowItem = ({ item = {} }) => {
  const { text = "", free = 0, pro = 0, premium = 1 } = item;

  return (
    <div className="w-full border-t-[3px] hover:bg-gray-50 border-borderGray flex items-stretch justify-start transition-all duration-150">
      <OneBoxText text={text} />
      <OneBoxCheck value={free} />
      <OneBoxCheck value={pro} />
      <OneBoxCheck value={premium} />
    </div>
  );
};

const OneBoxText = ({ text = "" }) => {
  return (
    <div className="w-1/4 py-[2vw]">
      <p className="text-[1.4vw] pl-[2.3vw]">{text}</p>
    </div>
  );
};

const OneBoxCheck = ({ value }) => {
  let checked = value === 1 ? "green" : "red";
  return (
    <div className="w-1/4 flex items-center justify-center">
      <img
        src={`/svgs/icons/check_${checked}.svg`}
        className="w-[2.5vw]"
        alt=""
      />
    </div>
  );
};
